








import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import TicketView from '@/components/views/support/TicketView.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
//import AuthModule from '@/store/modules/auth'
import SupportModule from '@/store/modules/support'

@Component({
  components: {
    TicketView,
  },
})
export default class SupportItem extends Mixins(NotifyMixin) {
  private get breadcrumbs () {
    return [
      {
        name: 'Техподдержка',
        path: this.$route.name === 'manager.support.item' ? '/manager/support' : '/master/support',
      },
    ]
  }

  private get ticket () {
    return SupportModule.ticket
  }

  private get ticketID () {
    return +this.$route.params.ticketID
  }

  private created () {
    this.fetchSupport()
  }

  private fetchSupport () {
    SupportModule.fetchTicket(this.ticketID)
      /*.then((response) => {
        if (response.hasNewMessages)
          AuthModule.decreaseUnreadTicketsCount()
      })*/
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.support.tickets' })
      })
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.ticket ? `Обращение #${this.ticket.id}` : 'Обращение',
    }
  }
}
